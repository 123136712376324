<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="标题">
          <el-input style="width: 200px" clearable v-model.trim="searchData.title" size="mini" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="handleAdd">新增</el-button>
    <el-table :data="list">
      <el-table-column prop="title" label="攻略标题" min-width="150"> </el-table-column>
      <el-table-column prop="img" label="封面" min-width="120">
        <template slot-scope="{ row }">
          <el-image style="width: 60px; height: 60px; border-radius: 4px; display: block" :src="row.img" :preview-src-list="[row.img]" fit="cover">
            <div slot="error" style="line-height: 60px; text-align: center; background: #f5f7fa">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="likes_num" label="喜欢人数" min-width="120"> </el-table-column>
      <el-table-column prop="status" label="状态" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="small">{{ row.status_dsc }}</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="small">{{ row.status_dsc }}</el-tag>
          <el-tag v-if="row.status == 20" type="primary" size="small">{{ row.status_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="manage_user" label="发布者" min-width="120">
        <template slot-scope="{ row }">
          {{ row.manage_user.account }}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" min-width="150"> </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="70%" @closed="close">
      <el-form :model="formData" ref="form" label-width="80px" size="mini">
        <el-form-item label="标题" prop="title" :rules="rules">
          <el-input style="width: 250px" clearable v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="img_list" :rules="rules">
          <UploaderImgs :background_images.sync="formData.img_list"></UploaderImgs>
        </el-form-item>
        <el-form-item label="封面" prop="img" :rules="rules">
          <UploaderImg :url.sync="formData.img"></UploaderImg>
          <div class="del-btn" v-if="formData.img">
            <el-link type="danger" @click="delCover">删除</el-link>
          </div>
        </el-form-item>
        <el-form-item label="内容" prop="content" :rules="rules">
          <Editor ref="editor" height="250px" :showBtn="false" :content.sync="formData.content"></Editor>
        </el-form-item>
        <el-form-item label="置顶时间">
          <el-date-picker style="width: 250px" v-model="formData.top_sort" size="mini" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="formData.status">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="10">不通过</el-radio>
            <el-radio :label="20">通过</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="show_update = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, delAPI, getDetailAPI, addAPI, editAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import UploaderImgs from '@/components/qiniu-uploader/uploader-imgs.vue'
import UploaderImg from '@/components/qiniu-uploader/uploader-img.vue'
import Editor from '@/components/editor/index.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { UploaderImgs, UploaderImg, Editor },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        title: ''
      },
      total: 0,
      formData: {
        title: '',
        content: '',
        status: 0,
        img: '',
        img_list: [],
        top_sort: ''
      }
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    delCover() {
      this.formData.img = ''
    },
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.data || []
      this.total = res.total || 0
    },
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.show_update = false
              this.getList()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.show_update = false
              this.getList()
            })
          }
        }
      })
    },
    //  add
    handleAdd() {
      this.formData = {
        title: '',
        content: '<p><br></p>',
        status: 0,
        img: '',
        img_list: [],
        top_sort: ''
      }
      this.show_update = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    close() {
      this.formData = {
        title: '',
        content: '<p><br></p>',
        status: 0,
        img: '',
        img_list: [],
        top_sort: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .del-btn {
    line-height: 20px;
    margin-top: -10px;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
