<template>
  <div class="container">
    <template v-if="uploadConf.qiniu_upload">
      <el-upload
        :action="uploadConf.qiniu_upload"
        :data="uploadConf"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :on-success="handleAvatarSuccess"
        :on-error="handleError"
        :before-upload="beforeAvatarUpload"
        :file-list="bgList"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :append-to-body="append">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { localget, localset } from '@/utils/localStorage'
import { getQiniuToken } from './api'
import { nanoid } from 'nanoid'
import { Loading } from 'element-ui'
export default {
  name: 'UploaderFile',
  data() {
    return {
      uploadType: '',
      uploadConf: {},
      dialogImageUrl: '',
      dialogVisible: false,
      bgList: [],
      loading1: null
    }
  },

  props: {
    background_images: {
      type: Array,
      default: () => []
    },
    append: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    background_images: {
      handler(newV, oldV) {
        if (newV) {
          this.bgList = newV
        }
      }
    }
  },
  created() {},
  mounted() {
    const config = localget('qiniuConfig')
    if (config) {
      this.uploadConf = config
    } else {
      this.getUploadConfig()
    }
  },
  methods: {
    async getUploadConfig() {
      const res = await getQiniuToken()
      // this.uploadType = res.uploadType
      this.uploadConf = res
      localset('qiniuConfig', this.uploadConf, 1800)
    },

    beforeAvatarUpload: function (file) {
      console.log('开始上传')
      this.loading1 = Loading.service({
        lock: true, //是否锁定屏幕的滚动
        spinner: 'el-icon-loading',
        text: '上传中', //loading下面的文字
        background: 'rgba(0, 0, 0, 0.0)', //loading的背景色
        target: 'body' //loading显示在容器
      })
      // this.getUploadConfig()
      let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
      this.uploadConf.key = nanoid() + '.' + fileExtension
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG/PNG 格式!')
        if (this.loading1) {
          this.loading1.close()
        }
        return false
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 10MB!')
        if (this.loading1) {
          this.loading1.close()
        }
        return false
      }
    },
    handleAvatarSuccess: function (res, file) {
      console.log('上传成功')
      console.log(file)
      const { name, uid } = file
      let url = this.uploadConf.domain + '/' + res.key
      this.bgList.push({ name, url, uid })
      this.$emit('update:background_images', this.bgList)
      if (this.loading1) {
        this.loading1.close()
      }
    },
    handleError: function (res) {
      if (this.loading1) {
        this.loading1.close()
      }
      this.$message.error({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    },
    handleRemove(file, fileList) {
      console.log(fileList)
      const { uid } = file
      this.bgList = this.bgList.filter((item) => uid != item.uid)
      this.$emit('update:background_images', this.bgList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.container /deep/ {
  /* 推荐，实现简单 */
  .el-upload-list__item.is-ready,
  .el-upload-list__item.is-uploading {
    display: none !important;
  }
}
</style>
